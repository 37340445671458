import modules from "ym";
import "./geocoder";

const dummy = () => {};

modules.define("geocoder_to_map_mark", ["geocoder"], (provide, geocoder) => {
  const zooms = {
    country: 6,
    province: 8,
    locality: 12,
    district: 14,
    streetName: 16,
    streetNumber: 17,
  };

  const yandexCountries = [
    "Азербайджан",
    "Армения",
    "Беларусь",
    "Білорусь",
    "Вірменія",
    "Грузия",
    "Грузія",
    "Казахстан",
    "Киргизия",
    "Киргизстан",
    "Республiка Молдова",
    "Республика Молдова",
    "Россия",
    "Росія",
    "Украина",
    "Україна",
  ];

  provide(function (options) {
    const limit = options.limit || null;
    const search = options.search || null;
    const locality = options.locality || null;
    const success = options.success || dummy;
    const error = options.error || dummy;
    const provider =
      options.country && yandexCountries.indexOf(options.country) === -1
        ? "google"
        : "yandex";

    geocoder({
      search: search,
      limit: limit,
      provider: provider,
      error(err) {
        error(err);
      },
      success(res) {
        let mark;
        let kind;
        let precision;
        let m_mark;

        res.forEach(function (item) {
          if (!mark) {
            if (locality && locality.match(/ялта/i)) {
              if (
                "locality" in item.properties &&
                item.properties.locality === locality
              ) {
                mark = item;
              }
            } else {
              mark = item;
            }
          }
        });

        if (mark) {
          m_mark = {
            lat: mark.geometry.coordinates[1],
            lng: mark.geometry.coordinates[0],
            color: "streetNumber" in mark.properties ? "green" : "red",
          };

          for (precision in zooms) {
            if (precision in mark.properties) {
              kind = precision;
            }
          }

          if (!kind) {
            m_mark.zoom = 7;
          } else if (kind in zooms) {
            m_mark.zoom = zooms[kind];
          } else {
            m_mark.zoom = zooms["country"];
          }

          success(m_mark);
        } else {
          success({ error: true });
        }
      },
    });
  });
});
