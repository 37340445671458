import "./_est-map.scss";
import "regenerator-runtime";

import $ from "jquery";
import modules from "ym";
import "../../modules/leaflet";
import "../../modules/leafletInfo";
import "../../modules/leafletButton";
import "../../modules/leafletFreeDraw";
import "../../modules/geocoder_to_map_mark";

import qs from "qs";
import url from "url";
import storage from "../../lib/storage";
import alert from "../../lib/alert";
import { language } from "../../lib/language";

const assign = Object.assign;
const $body = $("body");
const maxZoomDif = 1;
const maxNumberDif = 9;
const maxNumberFit = 20;
const minNumberFit = 3;
const maxFitZoom = 12;
const URL_POLYGON_GET = "/nedvizhimost/search_polygons/";
const URL_POLYGON_NEW = "/nedvizhimost/search_polygons/new/";
const LEAFLET_DEFAULT_LAYER_KEY = "leaflet-default-layer";

const MAPBOX_API_KEY =
  "pk.eyJ1IjoicGFydGVuaXQiLCJhIjoiY2lscDNmMHAwMDAzM3V6bHUza2pvZ2hpYyJ9.RHsTRpSkCwlllmSkBn3q-Q";

const LAYER_HYBRID = "hybrid";
const LAYER_ROADMAP = "roadmap";
const LAYER_SATELLITE = "satellite";
const LAYER_MAPBOX_LIGHT = "mapbox-light";
const LAYER_MAPBOX_STREETS = "mapbox-streets"; // mapbox + openstreetmap
const LAYER_OPENSTREETMAP = "openstreetmap";

// слои тайлов доступные по умолчанию
const LAYERS_DEFAULT = [LAYER_ROADMAP, LAYER_HYBRID];

// все доступные слои тайлов
const LAYERS_AVAILABLE = [
  LAYER_ROADMAP,
  LAYER_HYBRID,
  MAPBOX_API_KEY ? LAYER_MAPBOX_LIGHT : null,
  MAPBOX_API_KEY ? LAYER_MAPBOX_STREETS : null,
].filter((l) => l !== null);

const COPYRIGHT_MAPBOX =
  '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a>';

const COPYRIGHT_OPENSTREETMAP =
  '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';

const COPYRIGHT_MAPBOX_O = [COPYRIGHT_MAPBOX, COPYRIGHT_OPENSTREETMAP].join(
  ", "
);

const zooms = {
  country: 6,
  region: 8,
  region_id: 8,
  locality: 12,
  locality_id: 12,
};

const lang_prefix = "/" + language;

// центр Украины?
const ukraine = {
  lat: 48.54,
  lng: 31.18,
};

const freeDrawOpts = {
  maximumPolygons: 1,
};

function getUrlParam(urlToParse, param) {
  let parsed_url = url.parse(urlToParse);
  let parsed_query = qs.parse(parsed_url.query || "");

  return parsed_query[param] || null;
}

function addUrlParams(urlToParse, params) {
  let parsed_url = url.parse(urlToParse);
  let parsed_query = qs.parse(parsed_url.query || "");

  return url.format({
    protocol: parsed_url.protocol,
    host: parsed_url.host,
    pathname: parsed_url.pathname,
    search: qs.stringify({ ...parsed_query, ...params }),
    hash: parsed_url.hash,
  });
}

function delUrlParam(urlToParse, param) {
  let parsed_url = url.parse(urlToParse);
  let parsed_query = qs.parse(parsed_url.query || "");

  if (param in parsed_query) {
    delete parsed_query[param];
    return url.format({
      protocol: parsed_url.protocol,
      host: parsed_url.host,
      pathname: parsed_url.pathname,
      search: qs.stringify(parsed_query),
      hash: parsed_url.hash,
    });
  }

  return urlToParse;
}

function getEstMapWrappers() {
  return [...document.querySelectorAll(".est-map")];
}

function readOptions($wrapper) {
  let controls, layers, layer;

  const AVAILABLE_CONTROLS = ["zoom", "fullscreen", "layers"];
  const DEFAULT_CONTROLS = ["zoom", "fullscreen", "layers"];

  if ($wrapper.get(0).hasAttribute("data-controls")) {
    controls = ($wrapper.attr("data-controls") || "").split(/\s*[;,]\s*/);
    controls = controls.filter((c) => AVAILABLE_CONTROLS.indexOf(c) >= 0);
  } else {
    controls = DEFAULT_CONTROLS;
  }

  layers = ($wrapper.attr("data-layers") || "").split(/\s*[;,]\s*/);
  layers = layers.filter((l) => LAYERS_AVAILABLE.indexOf(l) >= 0);
  layers = layers.length ? layers : LAYERS_DEFAULT;
  layers = layers.filter((l) => LAYERS_AVAILABLE.indexOf(l) >= 0);

  layer = $wrapper.attr("data-layer");
  layer = layer || LAYER_HYBRID;
  layer = layers.indexOf(layer) >= 0 ? layer : layers[0];

  return {
    markLat: $wrapper.attr("data-mark-lat"),
    markLng: $wrapper.attr("data-mark-lng"),
    realLat: $wrapper.attr("data-real-lat"),
    realLng: $wrapper.attr("data-real-lng"),
    markSize: $wrapper.attr("data-mark-size"), // x|xx|xxx, default x
    markColor: $wrapper.attr("data-mark-color"), // red|orange|blue|green, default blue
    centerOffsetX: $wrapper.attr("data-center-offset-x"),
    centerOffsetY: $wrapper.attr("data-center-offset-y"),
    locality: $wrapper.attr("data-mark-locality"),
    country: $wrapper.attr("data-mark-country"),
    markAddress: $wrapper.attr("data-mark-address"),
    zoom: $wrapper.attr("data-zoom"),
    draggable: $wrapper.attr("data-mark-draggable") || false,
    record_id: $wrapper.attr("data-mark-balloon") || false,
    radius: $wrapper.attr("data-mark-radius") * 1,
    url: $wrapper.attr("data-url") || false,
    placeholder: $wrapper.attr("data-placeholder"),
    draw: $wrapper.attr("data-draw") || false,
    withAim: $wrapper.attr("data-aim"),
    geoJson: JSON.parse($wrapper.attr("data-geojson") || "null") || null,
    controls,
    layers,
    layer,
  };
}

function storeOptions(wrapper, options) {
  wrapper.attr("data-mark-color", options.markColor);
  wrapper.attr("data-mark-lat", options.markLat);
  wrapper.attr("data-mark-lng", options.markLng);
  wrapper.attr("data-zoom", options.zoom);
  wrapper.trigger("est-map-change");
}

function fetchGeoJsonByBounds(featuresUrl, bounds, zoom) {
  const bbox = `${bounds.nw.lat},${bounds.nw.lng},${bounds.se.lat},${bounds.se.lng}`;
  const params = {
    zoom: zoom,
    bbox: bbox,
  };

  // получение кластеров
  return $.ajax({
    url: addUrlParams(featuresUrl, params),
    dataType: "jsonp",
  });
}

function fetchAndShowBalloonForApplication({ map, marker, latLng, record_id }) {
  const popup = L.popup().setContent("Идет загрузка данных...");
  marker.bindPopup(popup);
  map.openPopup(popup, latLng);

  let data = {
    sf_format: "jsonp",
    record_id: record_id,
  };

  let priceCurrency = getUrlParam(window.location.href, "price_currency");
  if (priceCurrency) {
    data.currency = priceCurrency;
  }

  const error = () => {
    popup.setContent("Произошла ошибка при получении данных");
    map.openPopup(popup, latLng);
  };

  $.ajax({
    url: lang_prefix + "/apps-balloon/",
    dataType: "jsonp",
    data: data,
    error,
  }).then((balloon) => {
    if (balloon === "null") {
      error();
      return;
    }

    popup.setContent(prepareEstBalloonData(balloon));
    map.openPopup(popup, latLng);
    $(document).trigger("est-carousel:init");
  });

  return popup;
}

function prepareEstBalloonData(data) {
  let index = 0;
  let content;

  if (typeof data == "object") {
    let items = $.map(data, function (item) {
      index++;
      return `<li class="est-carousel__item" data-step="${index}">${item}</li>`;
    });

    content = `<div class="est-carousel est-carousel--wrap-circular est-carousel--theme-popup">
        <div class="est-carousel__prev"></div>
        <div class="est-carousel__next"></div>
        <div class="est-carousel__clip">
          <ul class="est-carousel__list">${items.join("")}</ul>
        </div>
        <div class="est-carousel__caption">
          <span class="est-carousel__caption-current"></span> из <span class="est-carousel__caption-total"></span>
        </div>
      </div>
    `;
  } else {
    content = data;
  }

  return content;
}

function createTileLayerGmap(L, type) {
  return L.gridLayer.googleMutant({ type });
}

function createTileLayerMB(L, style, attribution = "") {
  if (!MAPBOX_API_KEY) {
    return null;
  }
  return L.tileLayer(
    `https://api.mapbox.com/styles/v1/mapbox/${style}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_KEY}`,
    {
      tileSize: 512,
      zoomOffset: -1,
      attribution,
    }
  );
}

function createTileLayerOSM(L) {
  return L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution: COPYRIGHT_OPENSTREETMAP,
  });
}

function createTileLayers(L, layers) {
  const tileLayersByName = {};
  const tileLayersByTitle = {};

  for (let layerName of layers) {
    if (layerName === LAYER_ROADMAP) {
      const tileLayer = createTileLayerGmap(L, "roadmap");
      tileLayersByName[layerName] = tileLayer;
      tileLayersByTitle[`Карта`] = tileLayer;
    } else if (layerName === LAYER_SATELLITE) {
      const tileLayer = createTileLayerGmap(L, "satellite");
      tileLayersByName[layerName] = tileLayer;
      tileLayersByTitle[`Земля`] = tileLayer;
    } else if (layerName === LAYER_HYBRID) {
      const tileLayer = createTileLayerGmap(L, "hybrid");
      tileLayersByName[layerName] = tileLayer;
      tileLayersByTitle[`Гибрид`] = tileLayer;
    } else if (layerName === LAYER_MAPBOX_LIGHT && MAPBOX_API_KEY) {
      const tileLayer = createTileLayerMB(L, "light-v10", COPYRIGHT_MAPBOX);
      tileLayer && (tileLayersByName[layerName] = tileLayer);
      tileLayer && (tileLayersByTitle[`Mapbox Light`] = tileLayer);
    } else if (layerName === LAYER_MAPBOX_STREETS && MAPBOX_API_KEY) {
      const tileLayer = createTileLayerMB(L, "streets-v11", COPYRIGHT_MAPBOX_O);
      tileLayer && (tileLayersByName[layerName] = tileLayer);
      tileLayer && (tileLayersByTitle[`Mapbox Streets`] = tileLayer);
    } else if (layerName === LAYER_OPENSTREETMAP) {
      const tileLayer = createTileLayerOSM(L);
      tileLayer && (tileLayersByName[layerName] = tileLayer);
      tileLayer && (tileLayersByTitle[`OpenStreetMap`] = tileLayer);
    }
  }

  return {
    tileLayersByName,
    tileLayersByTitle,
  };
}

function createPointToLayerCallback(L) {
  return (feature, latlng) => {
    return L.marker(latlng, {
      icon: createIconForFeature(L, feature),
      riseOnHover: true,
    });
  };
}

function createOnEachFeatureCallback(L, map, markerFeaturesGroup = []) {
  return (feature, marker) => {
    // по спецификации geojson координаты идут в порядке lng, lat
    let coords = feature.geometry.coordinates;
    let latLng = L.GeoJSON.coordsToLatLng(coords);
    let props = feature.properties || {};
    let isCluster = "cluster" in props;
    let record_ids = props.record_id || props.record_ids || [];
    let description = props.description || null;

    let bounds = L.latLngBounds(latLng, latLng);
    if (bounds.overlaps(map.getBounds())) {
      markerFeaturesGroup.push(latLng);
    }

    if (description) {
      marker.bindPopup(description);

      return;
    }

    marker.on("click", function () {
      let showPopup = true;

      if (isCluster) {
        let zoomDiff = map.getMaxZoom() - map.getZoom();
        let isSmallCluster = props.cluster <= maxNumberDif;
        showPopup = isSmallCluster || zoomDiff <= maxZoomDif;
        showPopup = showPopup && true;
      }

      if (!showPopup) {
        map.setView(latLng, map.getZoom() + 1);

        return;
      }

      // получение данных и показ попапа для объявления
      fetchAndShowBalloonForApplication({
        map: map,
        marker: marker,
        latLng: latLng,
        record_id: record_ids,
      });
    });
  };
}

function storeGridOptions({ map, wrapper, coords, size }) {
  let $wrapper = $(wrapper);
  let bounds = map.getPixelBounds();

  // получение номеров с-з и ю-в тайлов
  let boundPoints = {
    nw: [Math.floor(bounds.min.x / size.x), Math.floor(bounds.min.y / size.y)],
    se: [Math.floor(bounds.max.x / size.x), Math.floor(bounds.max.y / size.y)],
  };

  // координаты граничных точек с-з и ю-в тайлов в пикселах
  let nwPoint = coords.scaleBy(size);
  let sePoint = L.point(nwPoint.x + size.x, nwPoint.y + size.y);

  // географические координаты граничных точек с-з и ю-в тайлов
  let nw = map.unproject(nwPoint, coords.z);
  let se = map.unproject(sePoint, coords.z);

  if (coords.x == boundPoints.nw[0] && coords.y == boundPoints.nw[1]) {
    $wrapper.attr("data-nw-lat", nw.lat);
    $wrapper.attr("data-nw-lng", nw.lng);
  }

  if (coords.x == boundPoints.se[0] && coords.y == boundPoints.se[1]) {
    $wrapper.attr("data-se-lat", se.lat);
    $wrapper.attr("data-se-lng", se.lng);
  }
}

function isPointInPolygon(latLngArr, polygon) {
  let x = latLngArr[0];
  let y = latLngArr[1];

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].x;
    let yi = polygon[i].y;
    let xj = polygon[j].x;
    let yj = polygon[j].y;

    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

function savePolygon(polygon, wrapper) {
  let data = {
    sf_format: "jsonp",
    polygon: polygon,
  };

  $.ajax({
    url: URL_POLYGON_NEW,
    dataType: "jsonp",
    type: "POST",
    data: data,
    beforeSend() {},
    error(data) {},
    success(data) {
      if (data.code != 0) {
        alert("Такой поиск уже сохранен. Попробуйте изменить область поиска", {
          title: "Ошибка",
        });
      } else {
        wrapper.attr("data-polygonID", data.md5);
        wrapper.trigger("est-map-change");

        // сохраняем полигон для отрисовки при возврате из списка
        // чтобы не делать лишний запрос на сервер
        storage.session.setItem("est-polygon-id", data.md5 || null);
        storage.session.setItem("est-polygon", polygon || null);

        let link = $('.apps-theme-switcher [data-key="list"] a').attr("href");
        if (link && link.length > 0) {
          let parsed_url = url.parse(link);
          let parsed_query = qs.parse(parsed_url.query || "");
          let url_res = url.format({
            pathname: parsed_url.pathname,
            search: qs.stringify(
              assign(parsed_query, {
                polygon_id: data.md5,
              })
            ),
          });

          location.assign(url_res + "#list-wrapper");
        }
      }
    },
  });
}

function removePolygon($wrapper) {
  storage.session.removeItem("est-polygon-id");
  storage.session.removeItem("est-polygon");
  $wrapper.attr("data-polygonID", "");
  $wrapper.trigger("est-map-change");

  // удаление параметра polygon_id из адресной строки
  const url_src = window.location.href;
  const url_res = delUrlParam(url_src, "polygon_id");
  if (url_src !== url_res && window.history.pushState) {
    window.history.pushState(null, null, url_res);
  }

  // удаление параметра polygon_id из ссылки переключателя типа отображения
  $(".apps-theme-switcher a").each(function (index, el) {
    const url_src = $(el).attr("href");
    const url_res = delUrlParam(url_src, "polygon_id");
    if (url_src !== url_res) {
      $(el).attr("href", url_res);
    }
  });
}

function createIconForFeature(L, feature) {
  let props = feature.properties || {};
  let color = props["marker-color"] || "blue";
  let size = props["marker-size"] || "x";
  let iconUrl = props["marker-url"] || null;
  let iconInfo = props["marker-leaflet"] || null;

  if (iconInfo) {
    return L.icon(iconInfo);
  }

  if (iconUrl) {
    return L.icon({ iconUrl });
  }

  if ("cluster" in props) {
    return L.divIcon({
      html: props.cluster,
      className: [
        "est-map__cluster",
        "est-map__cluster--" + getClusterIconSize(feature),
      ].join(" "),
    });
  }

  return L.icon(L.markers[`${color}-${size}`]);
}

// определение размера кластера
// в зависимости от количества объектов
function getClusterIconSize(feature) {
  let { cluster } = feature.properties || {};
  let clusterSize = parseInt(cluster) || 0;
  let iconSize = "x";
  iconSize = clusterSize > 10 ? "xx" : iconSize;
  iconSize = clusterSize > 100 ? "xxx" : iconSize;
  iconSize = clusterSize > 1000 ? "xxxx" : iconSize;

  return iconSize;
}

function initMaps(layer) {
  const LAYER_DEFAULT = layer || LAYER_ROADMAP;

  const createDiv = () => document.createElement("div");

  getEstMapWrappers().forEach(function (est_map_wrapper) {
    const wrapper = est_map_wrapper;
    const element = wrapper.querySelector(".est-map__area") || createDiv();
    const spacer = wrapper.querySelector(".est-map__spacer") || createDiv();
    const $wrapper = $(wrapper);
    const $element = $(element);
    const $spacer = $(spacer);

    let map = null;
    let marker = null;
    let circle = null;
    let freeDraw = null;

    let geoJsonLayer = null;
    let markerFeaturesGroup = [];

    let searchSelectBtn = null;
    let searchCancelBtn = null;
    let searchDoneBtn = null;
    let searchSaveBtn = null;
    let searchListBtn = null;
    let searchPopup = null;
    let searchPoints = [];

    let zoomStart = 0;
    let zoomEnd = 1;
    let isFitBoundsed = false;
    let layerLoaded = false;
    let canShowSearchListBtn = false;
    let fitPolygonBounds = false;
    let canZoom = true;

    $spacer.addClass("est-map__spacer");
    $spacer.appendTo(wrapper);

    $element.attr("class", "est-map__area");
    $element.on("contextmenu", (e) => e.preventDefault());
    $element.appendTo(wrapper);

    // подгружаем необходимые модули карты
    modules.require(
      ["leaflet", "geocoder_to_map_mark", "leafletButton", "leafletInfo"],
      function (L, geocoder_to_map_mark) {
        function createOrUpdateGeoJsonObjects() {
          const options = readOptions($wrapper);
          const geoJson = options.geoJson || null;

          if (!geoJson || !map) {
            return;
          }

          removeGeoJsonLayer();

          geoJsonLayer = L.geoJSON(geoJson, {
            pointToLayer: createPointToLayerCallback(L),
            onEachFeature: createOnEachFeatureCallback(L, map),
          }).addTo(map);

          const mapSize = map.getSize();
          const mapWidth = mapSize.x;
          const mapHeight = mapSize.y;

          map.fitBounds(geoJsonLayer.getBounds(), {
            paddingTopLeft: [mapWidth * 0.03, mapHeight * 0.15],
            paddingBottomRight: [mapWidth * 0.03, mapHeight * 0.02],
          });
        }

        function createOrUpdateMarker(passedOptions = {}) {
          const mapOptions = readOptions($wrapper);
          const markOptions = { ...passedOptions };
          const { draggable } = markOptions;

          if (!markOptions.lat || !markOptions.lng) {
            if (map && marker && map.hasLayer(marker)) {
              map.removeLayer(marker);
            }

            marker = null;

            return;
          }

          const markSize = markOptions.size;
          const markColor = markOptions.color;
          const markIcon = L.icon(L.markers[markColor + "-" + markSize]);
          const markLatlng = L.latLng(markOptions.lat, markOptions.lng);

          marker = marker || L.marker(markLatlng, { draggable });
          marker.setLatLng(markLatlng);
          marker.setIcon(markIcon);
          marker.off("dragend");

          map.hasLayer(marker) || map.addLayer(marker);

          if (mapOptions.centerOffsetX || mapOptions.centerOffsetY) {
            map.setViewWithOffset(
              markLatlng,
              map.getZoom(),
              [mapOptions.centerOffsetX || 0, mapOptions.centerOffsetY || 0],
              { animate: false }
            );
          } else {
            map.setView(markLatlng, map.getZoom(), { animate: false });
          }

          if (draggable) {
            marker.on("dragend", (e) => setLatLngViaMarker(e.target));
          }

          if (markOptions.record_id) {
            fetchAndShowBalloonForApplication({
              map: map,
              marker: marker,
              latLng: markLatlng,
              record_id: markOptions.record_id,
            });
          }

          if (markOptions.radius) {
            circle = L.circle(markLatlng, { radius: markOptions.radius });
            circle.addTo(map);

            const circleBounds = circle.getBounds();

            $wrapper.attr("data-circle-nw-lat", circleBounds._northEast.lat);
            $wrapper.attr("data-circle-nw-lng", circleBounds._southWest.lng);
            $wrapper.attr("data-circle-se-lat", circleBounds._southWest.lat);
            $wrapper.attr("data-circle-se-lng", circleBounds._northEast.lng);
          }

          if ($wrapper.hasClass("est-map--similar")) {
            reloadGeoJsonLayerByUrl();
          }
        }

        function createOrUpdateMap(passedOptions = {}) {
          const loadedOptions = readOptions($wrapper);
          const options = { ...loadedOptions, ...passedOptions };

          let center;
          let zoom;
          let availableTileLayerNames = loadedOptions.layers;
          let currentTileLayerName =
            availableTileLayerNames.indexOf(options.layer) >= 0
              ? options.layer
              : availableTileLayerNames[0];

          if (options.markLat && options.markLng) {
            center = [options.markLat, options.markLng];
          } else if (map) {
            center = map.getCenter();
          } else {
            center = [ukraine.lat, ukraine.lng];
          }

          if (options.zoom) {
            zoom = options.zoom;
          } else if (map) {
            zoom = map.getZoom();
          } else if (options.markLat && options.markLng) {
            zoom = 16;
          } else {
            zoom = 6;
          }

          if (!map) {
            const tileLayers = createTileLayers(L, availableTileLayerNames);
            const { tileLayersByName, tileLayersByTitle } = tileLayers;

            map = new L.Map(element, {
              zoom: zoom,
              center: center,
              layers: [],
              scrollWheelZoom: false,
              doubleRightClickZoomOut: true,
              zoomControl: loadedOptions.controls.indexOf("zoom") >= 0,
              fullscreenControl:
                loadedOptions.controls.indexOf("fullscreen") >= 0,
              fullscreenControlOptions: {
                title: "Включить полноэкранный просмотр",
                titleCancel: "Выйти из полноэкранного режима",
                forceSeparateButton: true,
                forcePseudoFullscreen: true,
                fullscreenElement: "body",
              },
            });

            if (true) {
              const currentTileLayer = tileLayersByName[currentTileLayerName];
              currentTileLayer.addTo(map);
              currentTileLayer.on("load", () => {
                map.invalidateSize(true);
                layerLoaded = true;
              });
            }

            // переключалку тайлов добавляем только если вариантов больше одного
            if (Object.keys(tileLayersByTitle).length > 1) {
              const tileLayerSwitcher = new L.Control.EstLayerControl(
                tileLayersByTitle,
                {},
                { collapsed: true }
              );
              tileLayerSwitcher.addTo(map);

              // при смене слоя запоминаем его
              map.on("baselayerchange", (e) => {
                storage.local.setItem(
                  LEAFLET_DEFAULT_LAYER_KEY,
                  e.layer.options.type
                );
              });
            }

            map.on("zoomstart", () => {
              zoomStart = map.getZoom();
            });

            map.on("zoomend", () => {
              zoomEnd = map.getZoom();
              $wrapper.attr("data-zoom", map.getZoom());
              $wrapper.trigger("est-map-change");
            });

            map.on("enterFullscreen", () => {
              $body.addClass("body-fullscreen");
              map.invalidateSize();
            });

            map.on("exitFullscreen", () => {
              $body.removeClass("body-fullscreen");
              map.invalidateSize();
            });

            if (loadedOptions.withAim !== "0") {
              const centerMarker = L.marker(center, {
                zIndexOffset: 1000,
                icon: L.icon({
                  iconUrl:
                    "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDI5MC42NTggMjkwLjY1OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkwLjY1OCAyOTAuNjU4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIyOTAuNjU4LDEzOS40NzcgMTUxLjE4MiwxMzkuNDc3IDE1MS4xODIsMCAxMzkuNDcxLDAgMTM5LjQ3MSwxMzkuNDc3IDAsMTM5LjQ3NyAwLDE1MS4xODUgICAgMTM5LjQ3MSwxNTEuMTg1IDEzOS40NzEsMjkwLjY1OCAxNTEuMTgyLDI5MC42NTggMTUxLjE4MiwxNTEuMTg1IDI5MC42NTgsMTUxLjE4NSAgIiBmaWxsPSIjOGM4YzhjIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==",
                  iconSize: [32, 32],
                  iconAnchor: [16, 16],
                }),
              });

              centerMarker.addTo(map);

              map.on("move", () => {
                centerMarker.setLatLng(map.getCenter());
              });
            }

            if (loadedOptions.draw) {
              let parsedPolygonId = getUrlParam(location.href, "polygon_id");
              if (parsedPolygonId) {
                Promise.all([
                  storage.session.getItem("est-polygon-id"),
                  storage.session.getItem("est-polygon"),
                ]).then((storedPolygonId, storedPolygon) => {
                  if (storedPolygon && storedPolygonId == parsedPolygonId) {
                    setAreaViaPolygon(storedPolygon);
                  } else {
                    $.ajax({
                      url: URL_POLYGON_GET,
                      dataType: "jsonp",
                      data: { polygon_id: parsedPolygonId },
                      error(data) {},
                      success(data) {
                        if (data.code != 0 || !data.polygon) {
                          createSearchSelectBtn();
                        } else {
                          setAreaViaPolygon(data.polygon.split(","));
                        }
                      },
                    });
                  }
                });
              } else {
                createSearchSelectBtn();
              }
            }

            if (readOptions($wrapper).url) {
              const tileLayerGrid = new L.GridLayer();

              tileLayerGrid.createTile = function (coords) {
                let tile = L.DomUtil.create("canvas", "leaflet-tile");
                let size = this.getTileSize();
                storeGridOptions({
                  wrapper: wrapper,
                  map: map,
                  size: size,
                  coords: coords,
                });
                return tile;
              };

              tileLayerGrid.addTo(map);

              map.on("moveend", () => {
                if (
                  readOptions($wrapper).url &&
                  !$wrapper.hasClass("est-map--loading")
                ) {
                  tileLayerGrid.redraw();
                  reloadGeoJsonLayerByUrl();
                }
              });
            }
          }

          map.setViewWithOffset(
            center,
            zoom,
            [options.centerOffsetX || 0, options.centerOffsetY || 0],
            { animate: false }
          );

          setTimeout(function () {
            if (!layerLoaded) {
              map.invalidateSize(true);
              layerLoaded = true;
            }
          }, 1000);
        }

        function createSearchSelectBtn() {
          searchSelectBtn = new L.Control.Button({
            class: "ui-button est-map__button",
            text: '<i class="fa fa-pencil" aria-hidden="true"></i> Выделить',
            onClick(e) {
              e.preventDefault();

              removePolygon($(wrapper));

              searchPopup && searchPopup.remove();
              searchSaveBtn && searchSaveBtn.remove();
              searchSelectBtn && searchSelectBtn.remove();
              searchListBtn && searchListBtn.remove();

              searchPoints = [];

              removeGeoJsonLayer();

              searchPopup = new L.Control.Info({
                class: "",
                text:
                  "Обведите нужный район на карте, чтобы определить область поиска",
              }).addTo(map);

              modules.require(["leafletFreeDraw"], (FreeDraw) => {
                freeDraw = freeDraw || new FreeDraw(freeDrawOpts);
                freeDraw.addTo(map);
                freeDraw.mode(FreeDraw.CREATE);
                freeDraw.clear();
                freeDraw.on("markers", function (e) {
                  if (
                    e.latLngs.length &&
                    searchPoints.length !== e.latLngs.length
                  ) {
                    searchPoints = e.latLngs;
                    searchPopup && searchPopup.remove();
                    searchPopup = new L.Control.Info({
                      class: "",
                      text: "Применить фильтр на карте?",
                    }).addTo(map);

                    createSearchCancelBtn("Отменить");
                    createSearchDoneBtn();
                  }
                });

                $wrapper.addClass("est-map--drawing");

                createSearchCancelBtn("Отменить");
              });
            },
            title: "Выделить область на карте",
          }).addTo(map);
        }

        function createSearchCancelBtn(text) {
          if (searchCancelBtn) {
            return;
          }

          searchCancelBtn = new L.Control.Button({
            class: "ui-button ui-button--grey est-map__button",
            text: text,
            onClick(e) {
              e.preventDefault();

              removePolygon($wrapper);

              searchPopup && searchPopup.hide();
              searchCancelBtn && searchCancelBtn.remove();
              searchCancelBtn = false;
              searchDoneBtn && searchDoneBtn.remove();
              searchSaveBtn && searchSaveBtn.remove();
              searchSelectBtn && searchSelectBtn.remove();
              searchListBtn && searchListBtn.remove();

              createSearchSelectBtn();

              $wrapper.removeAttr("data-circle-nw-lat");
              $wrapper.removeAttr("data-circle-nw-lng");
              $wrapper.removeAttr("data-circle-se-lat");
              $wrapper.removeAttr("data-circle-se-lng");

              canShowSearchListBtn = false;

              reloadGeoJsonLayerByUrl();

              if (freeDraw) {
                freeDraw.clear();
                freeDraw.mode(FreeDraw.NONE);
              }

              if (freeDraw && map.hasLayer(freeDraw)) {
                map.removeLayer(freeDraw);
              }

              $wrapper.removeClass("est-map--drawing");
              searchPoints = [];
            },
          }).addTo(map);
        }

        function createSearchDoneBtn() {
          searchDoneBtn = new L.Control.Button({
            class: "ui-button est-map__button",
            text: "Применить",
            onClick(e) {
              e.preventDefault();
              createSearchSelectBtn();
              createSearchCancelBtn("Очистить");
              // createSearchSaveBtn();

              if (searchPoints.length) {
                searchPoints = searchPoints[0];
                searchPoints = searchPoints.map((el) =>
                  L.point(el.lat, el.lng)
                );

                let searchBounds = L.bounds(searchPoints);

                $wrapper.attr("data-circle-nw-lat", searchBounds.max.x);
                $wrapper.attr("data-circle-nw-lng", searchBounds.min.y);
                $wrapper.attr("data-circle-se-lat", searchBounds.min.x);
                $wrapper.attr("data-circle-se-lng", searchBounds.max.y);

                canShowSearchListBtn = true;
                canZoom = false;
                reloadGeoJsonLayerByUrl();
              }

              freeDraw && freeDraw.mode(FreeDraw.NONE);
              $wrapper.removeClass("est-map--drawing");

              searchDoneBtn && searchDoneBtn.remove();
              searchPopup && searchPopup.remove();
              searchPopup = new L.Control.Info({
                class: "",
                text: "",
              }).addTo(map);
            },
          }).addTo(map);
        }

        function createSearchSaveBtn() {
          searchSaveBtn = new L.Control.Button({
            class:
              "ui-button ui-button--blue est-map__button est-dropdown-toggle",
            text: "Сохранить запрос",
            onClick: function (e) {
              e.preventDefault();
              alert("Данный раздел находится в разработке");
            },
          }).addTo(map);
        }

        function createSearchListBtn() {
          searchListBtn = new L.Control.Button({
            class: "ui-button ui-button--blue est-map__button",
            text: "Перейти к списку",
            onClick(e) {
              e.preventDefault();
              if (searchPoints.length) {
                let polygon = [];
                searchPoints.map((searchPoint) => {
                  polygon.push(searchPoint.x);
                  polygon.push(searchPoint.y);
                });

                savePolygon(polygon, $(wrapper));
              } else {
                let parsedPolygonId = getUrlParam(location.href, "polygon_id");
                if (parsedPolygonId) {
                  let link = $('.apps-theme-switcher [data-key="list"] a').attr(
                    "href"
                  );
                  location.assign(link + "#list-wrapper");
                }
              }
            },
          }).addTo(map);
        }

        function setAreaViaPolygon(polygon) {
          let points = [];
          let latLngs = [];

          for (let i = 0; i < polygon.length; i += 2) {
            points.push(L.point(polygon[i], polygon[i + 1]));
            latLngs.push({
              lat: polygon[i],
              lng: polygon[i + 1],
            });
          }

          createSearchCancelBtn("Очистить");
          createSearchSelectBtn();

          if (points.length) {
            let searchBounds = L.bounds(points);

            $wrapper.attr("data-circle-nw-lat", searchBounds.max.x);
            $wrapper.attr("data-circle-nw-lng", searchBounds.min.y);
            $wrapper.attr("data-circle-se-lat", searchBounds.min.x);
            $wrapper.attr("data-circle-se-lng", searchBounds.max.y);
          }

          modules.require(["leafletFreeDraw"], (FreeDraw) => {
            freeDraw = freeDraw || new FreeDraw(freeDrawOpts);
            freeDraw.addTo(map);
            freeDraw.mode(FreeDraw.NONE);

            fitPolygonBounds = latLngs;

            setTimeout(() => {
              freeDraw.create(latLngs);
              canShowSearchListBtn = true;
              reloadGeoJsonLayerByUrl();
            }, 1000);

            $wrapper.removeClass("est-map--drawing");

            searchPopup = new L.Control.Info({
              class: "",
              text: "",
            }).addTo(map);
          });
        }

        function setLatLngViaMarker(point) {
          const options = readOptions($wrapper);
          const latlng = point.getLatLng();

          storeOptions($wrapper, {
            markColor: "blue",
            markLat: latlng.lat,
            markLng: latlng.lng,
            zoom: map.getZoom(),
          });

          marker.setIcon(L.icon(L.markers["blue-x"]));

          map.setViewWithOffset(
            latlng,
            map.getZoom(),
            [options.centerOffsetX || 0, options.centerOffsetY || 0],
            {
              animate: true,
              pan: { duration: 0.25 },
            }
          );
        }

        function showCenterOfUkraine(draggable) {
          $wrapper.removeClass("est-map--loading");
          $wrapper.removeClass("est-map--placeholder");
          createOrUpdateMap({ lat: ukraine.lat, lng: ukraine.lng });
          createOrUpdateMarker({
            lat: ukraine.lat,
            lng: ukraine.lng,
            color: "red",
            size: "x",
            draggable: draggable,
          });
        }

        function removeGeoJsonLayer() {
          geoJsonLayer && geoJsonLayer.remove();
          geoJsonLayer = null;

          // очищаем массив (а не заменяем его на пустой)
          // т.е. таким образом не портим ссылки на этот массив в других местах
          markerFeaturesGroup.splice(0, markerFeaturesGroup.length);
        }

        function reloadGeoJsonLayerByUrl() {
          let options = readOptions($wrapper);

          if (!options.url) {
            removeGeoJsonLayer();

            return;
          }

          let circle_nw_lat = $wrapper.attr("data-circle-nw-lat");
          let circle_nw_lng = $wrapper.attr("data-circle-nw-lng");
          let circle_se_lat = $wrapper.attr("data-circle-se-lat");
          let circle_se_lng = $wrapper.attr("data-circle-se-lng");

          let nw_lat = $wrapper.attr("data-nw-lat");
          let nw_lng = $wrapper.attr("data-nw-lng");
          let se_lat = $wrapper.attr("data-se-lat");
          let se_lng = $wrapper.attr("data-se-lng");

          let bounds = {
            nw: {
              lat: Math.min(circle_nw_lat || nw_lat, nw_lat),
              lng: Math.max(circle_nw_lng || nw_lng, nw_lng),
            },
            se: {
              lat: Math.max(circle_se_lat || se_lat, se_lat),
              lng: Math.min(circle_se_lng || se_lng, se_lng),
            },
          };

          const xhr = fetchGeoJsonByBounds(options.url, bounds, map.getZoom());
          xhr.then((geoJson) => {
            // удаление предыдущих слоев кластеров
            removeGeoJsonLayer();

            geoJsonLayer = L.geoJSON(geoJson, {
              filter: (f) => {
                // отображаем только точки
                if (f.geometry.type !== "Point") {
                  return false;
                }

                // если нет поиска по геометрии, то отображаем все точки
                if (!searchPoints.length) {
                  return true;
                }

                // по спецификации geojson координаты идут в порядке lng, lat
                let coords = f.geometry.coordinates;
                let latLng = L.GeoJSON.coordsToLatLng(coords);
                let latLngArr = [latLng.lat, latLng.lng];

                // отобразим только те точки, что входят в искомую геометрию
                return isPointInPolygon(latLngArr, searchPoints);
              },
              pointToLayer: createPointToLayerCallback(L),
              onEachFeature: createOnEachFeatureCallback(
                L,
                map,
                markerFeaturesGroup
              ),
            }).addTo(map);

            if (
              markerFeaturesGroup.length < maxNumberFit &&
              zoomStart < zoomEnd &&
              !isFitBoundsed &&
              !circle
            ) {
              isFitBoundsed = true;

              if (fitPolygonBounds) {
                map.fitBounds(fitPolygonBounds, { padding: [60, 60] });
                fitPolygonBounds = false;
              } else if (markerFeaturesGroup.length < minNumberFit) {
                map.fitBounds(markerFeaturesGroup, {
                  maxZoom: maxFitZoom,
                });
              } else if (canZoom) {
                let fitZoom = 6;
                for (let zoom in zooms) {
                  if (getUrlParam(options.url, zoom)) {
                    fitZoom = zooms[zoom];
                  }
                }
                map.setZoom(fitZoom);
                // map.fitBounds(markerFeaturesGroup, {padding: [60, 60]});
              }
            } else if (zoomStart < zoomEnd && !isFitBoundsed && !circle) {
              isFitBoundsed = true;
              if (fitPolygonBounds) {
                map.fitBounds(fitPolygonBounds, { padding: [60, 60] });
                fitPolygonBounds = false;
              }
            }

            if (canShowSearchListBtn) {
              canShowSearchListBtn = false;
              createSearchListBtn();
            }

            canZoom = true;
          });
        }

        $wrapper.on("est-map-reload", function () {
          let options = readOptions($wrapper);

          const if_error = function () {
            $wrapper.toggleClass("est-map--loading", false);
            $wrapper.toggleClass("est-map--placeholder", !!options.placeholder);

            if (!options.placeholder) {
              createOrUpdateMap();
              createOrUpdateMarker();
            }
          };

          if (options.draw) {
            modules.require(["leafletFreeDraw"], (FreeDraw) => {});
          }

          if ((options.markLat && options.markLng) || options.geoJson) {
            $wrapper.removeClass("est-map--loading");
            $wrapper.removeClass("est-map--placeholder");
            createOrUpdateMap({
              markLat: options.markLat || null,
              markLng: options.markLng || null,
              zoom: options.zoom || null,
            });
          }

          if (options.geoJson) {
            createOrUpdateGeoJsonObjects();
          }

          if (options.markLat && options.markLng) {
            createOrUpdateMarker({
              lat: options.markLat || null,
              lng: options.markLng || null,
              size: options.markSize || "x",
              color: options.markColor || "red",
              draggable: options.draggable || false,
              balloon: options.balloon || false,
              radius: options.radius || false,
            });
          } else if (options.markAddress) {
            delete options.zoom;
            createOrUpdateMap();
            geocoder_to_map_mark({
              locality: options.locality,
              country: options.country,
              search: options.markAddress,
              limit: 3,
              success(mark) {
                if (mark) {
                  if ("error" in mark) {
                    let address = options.markAddress.split(/\s*,\s*/);
                    if (address.length > 2) {
                      address.pop();
                      address = address.join(", ");
                      address.slice(-2);
                      $wrapper.attr("data-mark-address", address);
                      $wrapper.attr("data-mark-color", "red");
                      $wrapper.trigger("est-map-reload");
                    } else {
                      showCenterOfUkraine(true);
                      map.invalidateSize();
                    }
                  } else {
                    $wrapper.removeClass("est-map--loading");
                    $wrapper.removeClass("est-map--placeholder");

                    options = assign(options, {
                      zoom: mark.zoom,
                      markLat: mark.lat,
                      markLng: mark.lng,
                      // markColor: options.markColor || mark.color,
                      markColor: mark.color,
                    });

                    createOrUpdateMap({
                      markLat: options.markLat || null,
                      markLng: options.markLng || null,
                      zoom: options.zoom || null,
                    });

                    if (!options.url) {
                      createOrUpdateMarker({
                        lat: options.markLat || null,
                        lng: options.markLng || null,
                        size: options.markSize || "x",
                        color: options.markColor || "blue",
                        draggable: options.draggable || false,
                      });
                      storeOptions($wrapper, {
                        markLat: options.markLat,
                        markLng: options.markLng,
                        markColor: options.markColor,
                        zoom: map.getZoom(),
                      });
                    }
                  }
                } else if (options.draggable) {
                  showCenterOfUkraine(true);
                  map.invalidateSize();
                } else {
                  console.log("Недостаточно данных для отображения маркера");
                  if_error();
                  map.invalidateSize();
                }
              },
              error: function () {
                console.log("Ошибка геокодирования");
                if (!options.url) {
                  showCenterOfUkraine(options.draggable || false);
                } else {
                  if_error();
                }
                map.invalidateSize();
              },
            });
          }

          if (
            !options.geoJson &&
            !options.markLat &&
            !options.markLng &&
            !options.markAddress
          ) {
            console.log("Не заданы координаты или адрес маркера");
            if_error();
          }
        });

        $wrapper.removeClass("est-map--placeholder");
        $wrapper.addClass("est-map--loading");
        $wrapper.trigger("est-map-reload");
      }
    );
  });
}

// карты на странице
if (getEstMapWrappers().length) {
  // "вспоминаем" предыдущий предпочтительный слой
  storage.local.getItem(LEAFLET_DEFAULT_LAYER_KEY, (storedLayer) => {
    initMaps(storedLayer || "roadmap");
  });
}

$(".est-map-hint__link").on("click", function (e) {
  e.preventDefault();
  let parsed_url = url.parse(location.href);
  let parsed_query = qs.parse(parsed_url.query || "");

  if ("polygon_id" in parsed_query) {
    delete parsed_query.polygon_id;

    location.assign(
      url.format({
        pathname: parsed_url.pathname,
        hash: parsed_url.hash,
        search: qs.stringify(parsed_query),
      })
    );
  }
});

$(document).on("est-map-load", ".est-map", function () {
  $(this).trigger("est-map-reload");
});

$(document).on("est-map-reload", ".est-static-map", function () {
  // тут было бы хорошо менять src у статической карты
  // см. EstObject::getMapImage
});

$(document).on("est-map-load", ".est-static-map", function () {
  const wrapper = $(this);

  wrapper.parents(".fc-row").attr("data-withmap", true);
  wrapper
    .html("")
    .removeClass("est-static-map")
    .addClass("est-map")
    .addClass("est-map--loading");

  storage.local.getItem(LEAFLET_DEFAULT_LAYER_KEY, (storedLayer) => {
    initMaps(storedLayer || "roadmap");
    wrapper.trigger("est-map-change");
  });
});
