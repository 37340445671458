import modules from "ym";
import "./loader_type_all";
import "./leaflet";
import "./gmaps";

const versions = require("./versions.json");
const basepath = `https://cdn.jsdelivr.net/npm/leaflet.gridlayer.googlemutant@${versions.leafletGoogleMutant}`;

modules.define(
  "leafletGoogleMutant",
  ["loader_type_all", "leaflet-base", "gmaps"],
  (provide, loader, L, gmaps) => {
    loader({
      js: basepath + "/Leaflet.GoogleMutant.min.js",
      success() {
        if (typeof L.GridLayer.GoogleMutant !== "undefined") {
          provide(L.GridLayer.GoogleMutant);
        }
      },
    });
  }
);
