import modules from "ym";
import "./leaflet";

modules.define("leafletInfo", ["leaflet-base"], (provide, L) => {
  L.Control.Info = L.Control.extend({
    options: {
      position: "topright",
    },

    initialize: function (options) {
      this._el = {};
      this.setEl(options);
    },

    hide: function () {
      L.DomUtil.removeClass(this._container, "est-map__info--shown");
    },

    show: function () {
      L.DomUtil.addClass(this._container, "est-map__info--shown");
    },

    onAdd: function (map) {
      this._map = map;

      this._container = L.DomUtil.create(
        "div",
        "est-map__info est-map__info--shown"
      );

      this._update();
      return this._container;
    },

    onRemove: function (map) {
      this._el = {};
      this._update();
    },

    setEl: function (options) {
      this._el = {
        class: options.class || "",
        text: options.text || "",
      };
      this._update();
    },

    _update: function () {
      if (!this._map) {
        return;
      }

      this._container.innerHTML = "";
      this._makeEl(this._el);
    },

    _makeEl: function (el) {
      var newEl = L.DomUtil.create("span", el.class, this._container);
      newEl.innerHTML = el.text;
      return newEl;
    },
  });

  provide(L.Control.Info);
});
