import markers from "./markers";

export default function (L) {
  /**
   * Иконки маркеров
   */
  L.markers = markers;

  L.Map.prototype.setViewWithOffset = function (
    latlng,
    zoom,
    offset,
    options = {}
  ) {
    const mapSize = this.getSize();
    const intOffsetX = parseInt(offset[0]);
    const intOffsetY = parseInt(offset[1]);

    const offsetX = ("" + offset[0]).match(/^\d+%$/)
      ? (mapSize.x * intOffsetX) / 100
      : intOffsetX;

    const offsetY = ("" + offset[1]).match(/^\d+%$/)
      ? (mapSize.y * intOffsetY) / 100
      : intOffsetY;

    const x = this.latLngToContainerPoint(latlng).x - offsetX;
    const y = this.latLngToContainerPoint(latlng).y - offsetY;

    const point = this.containerPointToLatLng([x, y]);

    return this.setView(point, zoom, options);
  };

  /**
   * Создаем новый виджет слоев
   *
   * Раньше тут делали хак, чтоб переключалка слоев находилась внутри div а не form,
   * но потом leaflet заменили form на section, и поэтому наш хак мы убрали
   */
  if (true) {
    L.Control.EstLayerControl = L.Control.Layers.extend({});
  }

  // Отдаление зума при двойном клике правой кнопки мыши
  if (true) {
    L.RightClickZoomOut = L.Handler.extend({
      doubleRightClick: 0,

      addHooks: function () {
        this._map.on("mousedown", this._onDoubleClick, this);
      },

      removeHooks: function () {
        this._map.off("mousedown", this._onDoubleClick, this);
      },

      _onDoubleClick: function (e) {
        if (e.originalEvent.button == 2) {
          L.DomEvent.preventDefault(this);

          this.doubleRightClick++;

          if (this.doubleRightClick > 1) {
            const map = this._map;
            const zoom = map.getZoom() - 1;
            map.setZoom(zoom);
          }

          setTimeout(() => this._clearDoubleRightClick(), 600);

          return false;
        }
      },

      _clearDoubleRightClick: function () {
        this.doubleRightClick = 0;
      },
    });

    L.Map.addInitHook(
      "addHandler",
      "doubleRightClickZoomOut",
      L.RightClickZoomOut
    );
  }

  // патчим иконку маркера по умолчанию
  // т.к. leaflet пытается определить урл иконки читая css стили,
  // в которых webpack меняет названия картинок,
  // и соответственно leaflet спотыкается на этом.
  // Для исправления мы скопировали картинки туда, куда webpack не лезет
  if (true) {
    L.Icon.Default.prototype._getIconUrl = function (name) {
      return (
        "/web/images/leaflet/" + L.Icon.prototype._getIconUrl.call(this, name)
      );
    };
  }
}
