import modules from "ym";
import "./leaflet";

modules.define("leafletButton", ["leaflet-base"], (provide, L) => {
  L.Control.Button = L.Control.extend({
    options: {
      position: "topright",
    },

    initialize: function (options) {
      this._button = {};
      this.setButton(options);
    },

    onAdd: function (map) {
      this._map = map;

      this._container = L.DomUtil.create(
        "div",
        "leaflet-control-button est-map__leaflet-control-button"
      );

      this._update();
      return this._container;
    },

    onRemove: function (map) {
      this._button = {};
      this._update();
    },

    setButton: function (options) {
      const button = {
        class: options.class || "ui-button",
        text: options.text || "",
        onClick: options.onClick,
        title: options.title || "",
      };

      this._button = button;
      this._update();
    },

    _update: function () {
      if (!this._map) {
        return;
      }

      this._container.innerHTML = "";
      this._makeButton(this._button);
    },

    _makeButton: function (button) {
      const newButton = L.DomUtil.create("a", button.class, this._container);
      newButton.href = "#";
      newButton.innerHTML = button.text;
      newButton.title = button.title;

      const onClick = (e) => {
        button.onClick(e, newButton);
      };

      L.DomEvent.addListener(newButton, "click", onClick, this);

      return newButton;
    },
  });

  provide(L.Control.Button);
});
