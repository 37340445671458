"use strict";

import modules from "ym";
import "./loader_type_all";
import "./leaflet";

const versions = require("./versions.json");
const basepath = `https://cdn.jsdelivr.net/npm/leaflet.fullscreen@${versions.leafletFullScreen}`;

modules.define(
  "leafletFullScreen",
  ["loader_type_all", "leaflet-base"],
  (provide, loader, L) => {
    loader({
      js: basepath + "/Control.FullScreen.min.js",
      css: basepath + "/Control.FullScreen.min.css",
      // img: basepath + '/icon-fullscreen-2x.png',
      // img: basepath + '/icon-fullscreen.png',
      success() {
        if (typeof L.Control.FullScreen !== "undefined") {
          provide(L.Control.FullScreen);
        }
      },
    });
  }
);
