import modules from "ym";
import qs from "qs";
import { language } from "../lib/language";

// const api_key = 'AIzaSyC6EGfoeZ7Me_Lhvydyg75DyQ4qG2PLNNI';
// const api_key = 'AIzaSyDrz448AToASzwds4Vlu4SSWZuVX_g1n1k';
const api_key = "AIzaSyBAojyT9AfPMk1VBHDvtooLiYF8LhYFd_o";
const api_url = "https://maps.googleapis.com/maps/api/js";

const callback_name = "est_gmaps_onload";
const lang = language === "ua" ? "uk" : "ru";

modules.define("gmaps-api-key", function (provide) {
  provide(api_key);
});

modules.define("gmaps", ["loader_type_all"], function (provide, loader) {
  window[callback_name] = function () {
    if (typeof window.google.maps !== "undefined") {
      provide(window.google.maps);
    }
  };

  loader({
    js:
      api_url +
      "?" +
      qs.stringify({
        key: api_key,
        callback: callback_name,
        language: lang,
      }),
  });
});
