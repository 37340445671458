import modules from "ym";
import "./jquery";

const xhrs = {};
const cache = {};

modules.define("geocoder-ymaps-proxy", ["jquery"], function (provide, $) {
  const dummy = function () {};

  provide(function (options) {
    const success = options.success || dummy;
    const error = options.error || dummy;
    const data = {
      search: options.search || null,
      limit: options.limit || null,
      provider: options.provider || "yandex",
      format: "geojson",
      sf_format: "jsonp",
    };

    const request_id = JSON.stringify(data);

    if (request_id in cache) {
      // такой запрос уже выполнялся и есть в кэше
      // значит просто отдадим результат (через 10 мсек)

      setTimeout(() => {
        if (cache[request_id] === "ERROR") {
          error();
        } else {
          success(cache[request_id]);
        }
      }, 10);
    } else {
      let xhr =
        xhrs[request_id] ||
        $.ajax({
          url: "//" + sf_host + "/geocoder/",
          data: data,
          dataType: "jsonp",
          // timeout: 1000 * 10,
          error: () => {
            cache[request_id] = "ERROR";
          },
          success: (data) => {
            cache[request_id] = data;
          },
        });

      xhr.fail(() => {
        success(error);
      });

      xhr.done((data) => {
        success(data);
      });

      xhrs[request_id] = xhr;
    }
  });
});
