import modules from "ym";
import "./loader_type_all";
import "./leafletGoogleMutant";
import "./leafletFullScreen";

import presets from "../lib/leaflet/presets";

const versions = require("./versions.json");
const basepath = `https://cdn.jsdelivr.net/npm/leaflet@${versions.leaflet}`;

modules.define(
  "leaflet",
  ["leaflet-base", "leafletGoogleMutant", "leafletFullScreen"],
  (provide, L) => {
    provide(L);
  }
);

modules.define("leaflet-base", ["loader_type_all"], (provide, loader) => {
  require("core-js/features/string/trim");

  loader({
    js: basepath + "/dist/leaflet.js",
    css: basepath + "/dist/leaflet.css",
    success() {
      if (typeof global.L !== "undefined") {
        presets(global.L);
        provide(global.L);
      }
    },
  });
});
