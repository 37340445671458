"use strict";

import modules from "ym";
import "./loader_type_all";
import "./leaflet";
import isFunction from "lodash/isFunction";

const versions = require("./versions.json");
const basepath = `https://cdn.jsdelivr.net/npm/leaflet-freedraw@${versions.leafletFreeDraw}`;

modules.define(
  "leafletFreeDraw",
  ["loader_type_all", "leaflet-base"],
  (provide, loader) => {
    loader({
      js: `${basepath}/dist/leaflet-freedraw.iife.js`,
      error() {
        provide(null, Error("Failed to load leaflet-freedraw"));
      },
      success() {
        if (
          typeof global.FreeDraw !== "undefined" &&
          isFunction(global.FreeDraw)
        ) {
          provide(global.FreeDraw);
        } else {
          provide(null, Error("Failed to load leaflet-freedraw"));
        }
      },
    });
  }
);
