import $ from "jquery";
import "../components/est-dialog/est-dialog";

let noop = () => {};

export default function (message, options) {
  let params, html, dialog, result;

  params = options || {};
  html = "" + message;
  html = html.match(/[<>]/) ? html : html.replace(/[\n]/g, "<br />");
  dialog = $("<div />").html(html);
  result = false;

  dialog.estdialog({
    title: params.title || null,
    width: params.width || 400,
    destroyOnClose: true,
    resizable: false,
    draggable: true,
    modal: true,
    close: function () {
      if (result) {
        (params.success || noop).apply();
      } else {
        (params.fail || noop).apply();
      }
    },
    buttons: [
      {
        text: params.buttonText || "ОК",
        class:
          "ui-button ui-button--big ui-button--orange command-close-dialog",
        click: () => {
          result = true;
        },
      },
    ],
  });
}
