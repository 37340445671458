const markers = {};

const markerColors = ["red", "green", "blue", "orange"];
const markerSizes = {
  x: {
    iconAnchor: [14, 40],
    iconSize: [43, 40],
    popupAnchor: [0, -22],
  },
  xx: {
    iconAnchor: [22, 60],
    iconSize: [65, 60],
    popupAnchor: [0, -34],
  },
  xxx: {
    iconAnchor: [28, 80],
    iconSize: [87, 80],
    popupAnchor: [0, -48],
  },
};

/**
 * red-x
 * green-xx
 * blue-xxx
 * orange-xx
 * ...
 */
Object.entries(markerSizes).forEach(([size, sizeInfo]) => {
  markerColors.forEach((color) => {
    markers[`${color}-${size}`] = {
      iconUrl: `/web/images/map/ico-marker-${color}-${size}.png`,
      ...sizeInfo,
    };
  });
});

export default markers;
